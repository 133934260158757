import React from 'react';
import PrintingView from './PrintingView.js'
import logo from './logo.svg';
import './App.css';
import FrontPage from './Frontpage'
import { useSelector } from 'react-redux'
import CreatorSlideshows from './CreatorSlideshows'
import { Route } from 'react-router'
import EditSlideshow from './EditSlideshow'
import ViewSlideshow from './ViewSlideshow'
function App() {
  const userId = useSelector(x => x.userId)
  const dead = useSelector(x => x.dead)
  if (dead) {
    var url = window.location.href



    var arr = url.split("/");

    var result = arr[0] + "//" + arr[2] + "/web"

    return (<div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div style={{ maxWidth: "500px" }}>
        You have this presentation open in another tab or on another computer.
        Because of the autosave feature, it is impossible to work on a presentation
        at the same time in two different browser tabs.
        It's also possible that you received this error because we are doing server maintenance.
        <div style={{ display: "flex", justifyContent: "center" }}><a href={result}>Go back home</a></div>



      </div>

    </div>)
  }
  return <>
    <Route path="/web" exact={true} component={CreatorSlideshows} />
    <Route path="/web/slideshow/modify/:slideshowId" exact={true} component={EditSlideshow} />
    <Route path="/web/slideshow/view/:slideshowId" exact={true} component={ViewSlideshow} />
    <Route path="/web/slideshow/print/:slideshowId" exact={true} component={PrintingView} />

  </>


}

export default App;
