let frontendhost = "https://slideswithsounds.com"
let backendhost = `https://slideswithsounds.com`
// if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
//     backendhost = 'http://localhost:2083'
//     frontendhost = `http://localhost:3000`
// }
let apihost = backendhost + "/api"

const post = async (url, body) => {
    url = apihost + url
    let data = new FormData()
    for (let k in body) {
        data.append(k, body[k])
    }
    console.log("sending to", url)
    let resp = await fetch(url, {
        method: "POST",
        body: data
    })
    return resp.text()
}
let urlFromId = id => {
    return apihost + "/user-uploaded/" + id
}

const rawpost = async (url, body) => {
    url = apihost + url

    let resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body)
    })
    return resp.text()
}

export { post, rawpost, urlFromId, frontendhost }