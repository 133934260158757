import { createStore } from 'redux'
import produce from 'immer'

let reducer = (state, action) => {
    if (action.type === "user-login") {
        return {
            ...state,
            creatorSlideshows: action.slideShows,
            userId: action.userId
        }
    }
    // creator
    if (action.type === "add-slideshow") {
        let currentSlideshows = state.creatorSlideshows || []
        let newSlideshows = produce(currentSlideshows, x => {
            x.push({ slideshow_name: action.slideshowName, slideshow_id: action.slideshowId, slides: [] })
        })
        return {
            ...state,
            creatorSlideshows: newSlideshows,
        }
    }

    if (action.type === "delete-creator-slideshow") {
        let currentSlideshows = state.creatorSlideshows || []
        return {
            ...state,
            creatorSlideshows: currentSlideshows.filter(x => x.slideshow_id !== action.slideshowId)
        }

    }

    if (action.type === "auth-check") {

        return {
            ...state,
            authCheck: true
        }

    }






    // viewer
    if (action.type === "set-presenting-index") {
        return {
            ...state,
            presentingSlideIndex: action.index
        }
    }
    if (action.type === "increment-viewed-slide-index") {
        return {
            ...state,
            initialPress: true,
            presentingSlideIndex: state.presentingSlideIndex + 1
        }
    }
    if (action.type === "decrement-viewed-slide-index") {
        return {
            ...state,
            initialPress: true,
            presentingSlideIndex: state.presentingSlideIndex - 1
        }
    }
    if (action.type === "set-viewed-slideshow") {
        if (!action.slideshow) {
            throw new Error("huh")
        }
        return {
            ...state,
            viewedSlideshow: action.slideshow.data,
            viewedSoundfile: action.slideshow.soundFile
        }
    }
    if (action.type === "dead") {
        return {
            ...state,
            dead: true
        }
    }
    if (action.type === "set-presenting-id") {
        if (!action.presentingId) {
            throw new Error("what")
        }
        return {
            ...state,
            presentingId: action.presentingId
        }
    }

    if (action.type === "set-presenting-status") {
        return {
            ...state,
            initialPress: true,
            presentingStatus: action.presenting
        }
    }

    if (action.type === "time-at-pause") {

        return {
            ...state,
            timeAtPause: action.payload
        }

    }

    return state

}
let store = createStore(reducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
let dispatch = store.dispatch
let getState = () => store.getState()
export { store, dispatch, getState }