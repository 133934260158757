import * as uniqid from 'uniqid';
import { IconContext } from "react-icons";
import ClipLoader from "react-spinners/ClipLoader";
import React from 'react'
import { useEffect, useState } from 'react'
import produce from 'immer'
import { post } from './requests'
import { useSelector } from 'react-redux'
import { urlFromId, frontendhost } from './requests'
import FrontPage from './Frontpage'
import { FaTrash } from 'react-icons/fa'
import { playSound, stopSound } from './play-sound'
import { MdModeEdit, MdRecordVoiceOver, MdSwapVert, MdAdd, MdStop, MdPlayArrow } from 'react-icons/md'
import { GrVolumeMute } from 'react-icons/gr'
import { BsUpload } from 'react-icons/bs'
import AudioRecorder from 'audio-recorder-polyfill'
import { dispatch } from './store';
window.MediaRecorder = AudioRecorder

const delay = t => new Promise((res, rej) => setTimeout(res, t))

const recordAudio = (state) => {
    return new Promise(resolve => {
        let constraints = state.deviceIdSelected ?
            { audio: { deviceId: { exact: state.deviceIdSelected } } } :
            { audio: true }
        console.log("constraints", constraints)
        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                const audioChunks = [];

                mediaRecorder.addEventListener("dataavailable", event => {
                    audioChunks.push(event.data);
                });

                const start = () => {
                    mediaRecorder.start();
                };

                const stop = () => {
                    return new Promise(resolve => {
                        mediaRecorder.addEventListener("stop", () => {
                            const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
                            const audioUrl = URL.createObjectURL(audioBlob);
                            const audio = new Audio(audioUrl);
                            const play = () => {
                                audio.play();
                            };

                            resolve({ audioBlob, audioUrl, play });
                        });

                        mediaRecorder.stop();
                        stream.getTracks() // get all tracks from the MediaStream
                            .forEach(track => track.stop()); // stop each of them
                    });
                };

                resolve({ start, stop });
            });
    });
};

let playing = undefined



function EditSlideshow(props) {
    let slideshowId = props.match.params.slideshowId
    let userId = useSelector(x => x.userId)
    let dead = useSelector(x => x.dead)
    const [state, setState] = useState({
        data: undefined
    })

    let playAudioURL = url => {
        try {
            if (playing) playing.pause()
        } finally { }
        playing = new Audio(url);
        playing.play()
        setState(produce(state, state => {
            state.playingAudio = true
        }))
        playing.addEventListener("ended", function () {
            setState(produce(state, state => {
                state.playingAudio = false
            }))
        });
    }

    let stopAudio = () => {
        try {
            if (playing) playing.pause()
        } finally { }
        setState(produce(state, state => {
            state.playingAudio = false
        }))
    }



    let save = async (newState) => {
        if (dead) return
        if (!newState) newState = state
        let toSave = newState.data
        toSave = produce(toSave, d => {
            d.forEach(e => {
                e.soundURL = undefined
            })
        })
        let result = await post('/save-slideshow', {
            slideshowId: slideshowId,
            userId,
            data: JSON.stringify(toSave),
            presentationLockId: state.presentationLockId
        })
        let parsed = JSON.parse(result)
        if (!parsed.success && parsed.invalidLock) {
            dispatch({ type: "dead" })
            return
        }
        setState(state => produce(state, state => {
            state.needSaving = false
        }))

    }

    let publish = async (newState) => {



        setState(produce(state, state => {
            state.uploading = true
        }))

        if (!newState) newState = state
        let toSave = newState.data
        // let hasSound = false
        // for (let i = 0; i < hasSound.length; i++) {
        //     let d = toSave[i]
        //     if (d.soundId) hasSound = true
        // }
        // if (!hasSound) {
        //     alert("Unable to publish: None of your slides have sounds")
        //     setState(produce(state, state => {
        //         state.uploading = false
        //     }))

        //     return
        // }

        toSave = produce(toSave, d => {
            d.forEach(e => {
                e.soundURL = undefined
            })
        })
        await post('/publish-slideshow', {
            slideshowId: slideshowId,
            userId,
            data: JSON.stringify(toSave)
        })

        setState(produce(state, state => {
            state.uploading = false
        }))

    }

    useEffect(() => {
        if (!state.needSaving) return
        let timeout = setTimeout(() => {
            if (!state.needSaving) return
            save(state)
        }, 2000)
        return () => {
            clearTimeout(timeout)
        }
    })
    useEffect(() => {
        let lastDeviceId = window.localStorage.getItem('lastMicDeviceId');
        let lastDeviceLabel = window.localStorage.getItem('lastMicDeviceLabel');
        if (!lastDeviceId && !lastDeviceLabel) {
            return
        }
        function gotDevices(deviceInfos) {
            for (let i = 0; i !== deviceInfos.length; ++i) {
                var deviceInfo = deviceInfos[i];
                if (deviceInfo.kind === 'audioinput') {
                    let deviceIdMatch = lastDeviceId && deviceInfo.deviceId === lastDeviceId
                    let deviceLabelMatch = deviceInfo.label && deviceInfo.label === lastDeviceLabel
                    if (deviceIdMatch || deviceLabelMatch) {
                        setState(state => produce(state, state => {
                            state.micIndexSelected = i
                            state.deviceIdSelected = deviceInfo.deviceId
                        }))
                        return
                    }
                }
                // if (deviceInfo.kind === 'audioinput') {
                //     console.log("device", deviceInfo.label || 'Microphone ' + i,
                //         deviceInfo.deviceId);
                // }
            }
            console.log("did not found a match")
        }

        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

            navigator.mediaDevices.enumerateDevices()
                .then(gotDevices)
                .then(() => {
                    stream.getTracks() // get all tracks from the MediaStream
                        .forEach(track => track.stop()); // stop each of them
                })
                .catch(err => {
                    console.log("error", err)
                })
        })

    }, [])
    useEffect(() => {
        const act = async () => {
            let result = await post('/get-slideshow', {
                slideshowId: slideshowId
            })
            result = JSON.parse(result)
            const slideshow = result.slideshow.slides
            let slideshowName = result.slideshow.name

            if (!slideshow) {
                setState(produce(state, state => {
                    state.data = null

                }))
                return
            }
            slideshow.forEach(slide => {
                if (!slide.slideId) {
                    slide.slideId = uniqid()
                }
            })

            setState(produce(state, state => {
                state.data = slideshow
                state.slideshowName = slideshowName
                state.presentationLockId = result.presentationLockId
            }))
        }
        act()
    }, [slideshowId])
    if (state.data === undefined) return "loading slideshow data"
    if (state.data === null) return "no slideshow with that id"

    let imageChange = async (event, idx) => {
        let file = event.target.files[0]
        let result = await post("/upload-image", {
            image: file
        })
        result = JSON.parse(result)
        let id = result.id
        const newState = produce(state, state => {
            state.adding = undefined
            state.data.splice(idx, 0,
                {
                    slideId: uniqid(),
                    imageId: id,
                    text: "",
                    soundURL: undefined,
                    soundId: undefined

                })
            return

        })
        setState(newState)
        save(newState)
    }

    let changeImageAtIndex = async (event, idx) => {
        let file = event.target.files[0]
        let result = await post("/upload-image", {
            image: file
        })
        result = JSON.parse(result)
        let id = result.id
        let newState = produce(state, state => {
            state.data[idx].imageId = id
            state.changeImageModal = undefined
        })
        setState(newState)
        save(newState)
    }

    let pdfChange = async (event, idx) => {
        let file = event.target.files[0]
        setState(produce(state, state => {
            state.adding = false
            state.uploading = true
        }))
        let result = await post("/upload-pdf", {
            pdf: file
        })
        try {
            result = JSON.parse(result)
        } catch (e) {
            alert("A strange error occurred")
            setState(produce(state, state => {
                state.uploading = false
            }))
            return
        } finally { }
        if (!result.success) {
            alert("Unable to process pdf")
            setState(produce(state, state => {
                state.uploading = false
            }))
            return
        }
        let ids = result.ids
        ids.reverse()
        let newState = produce(state, state => {
            state.uploading = false
            state.adding = undefined
            ids.forEach(id => {
                state.data.splice(idx, 0, {
                    slideId: uniqid(),
                    imageId: id,
                    text: "",
                    soundURL: undefined,
                    soundId: undefined
                })
            })
        })
        setState(newState)
        save(newState)
    }

    let replaceAllImages = async (event) => {
        let file = event.target.files[0]
        setState(produce(state, state => {
            state.uploading = true
        }))
        let result = await post("/upload-pdf", {
            pdf: file
        })
        try {
            result = JSON.parse(result)
        } catch (e) {
            alert("A strange error occurred")
            setState(produce(state, state => {
                state.uploading = false
            }))
            return
        }
        if (!result.success) {
            alert("Unable to process pdf")
            setState(produce(state, state => {
                state.uploading = false
            }))
            return
        }
        let ids = result.ids
        let newState = produce(state, state => {
            state.uploading = false
            state.adding = undefined
            let i = 0
            for (; i < ids.length && i < state.data.length; i++) {
                state.data[i].imageId = ids[i]
            }
            for (; i < ids.length; i++) {
                let id = ids[i]
                state.data.splice(i, 0, {
                    slideId: uniqid(),
                    imageId: id,
                    text: "",
                    soundURL: undefined,
                    soundId: undefined
                })
            }
        })
        setState(newState)
        save(newState)
    }


    const uploadSoundFile = async (file, idx) => {
        setState(produce(state, state => {
            state.uploading = true
        }))
        let resp = await post("/upload-audio-file", {
            "audio-file": file
        })
        resp = JSON.parse(resp)
        let soundId = resp.id

        let newState = produce(state, state => {
            state.uploading = false
            state.data[idx].soundId = soundId
            state.data[idx].soundURL = undefined
            state.recording = undefined
        })

        setState(newState)
        save(newState)
    }


    const stopRecording = async () => {
        setState(produce(state, state => {
            state.uploading = true
        }))


        let { audioBlob, audioUrl } = await state.recording.stop()
        let idx = state.recording.idx
        let resp = await post("/upload-wav", {
            wav: audioBlob
        })
        resp = JSON.parse(resp)
        let soundId = resp.id
        let newState = produce(state, state => {
            state.uploading = false
            state.data[idx].soundId = soundId
            state.data[idx].soundURL = audioUrl
            state.recording = undefined
        })

        setState(newState)
        save(newState)
    }
    const recordForSlide = async idx => {
        stopAudio()
        console.log("starting recording")
        let { start, stop } = await recordAudio(state)
        setState(produce(state, state => {
            state.recording = { idx, stop }
        }))
        start()


    }
    let textAreaChanged = (idx, evt) => {
        setState(produce(state, state => {
            state.data[idx].text = evt.target.value
            state.needSaving = true
        }))
    }


    let deleteSlide = idx => {
        let newState = produce(state, state => {

            state.data.splice(idx, 1)


        })
        setState(newState)
        save(newState)
    }

    let moveUp = (idx) => {
        if (idx === 0) return
        let newState = produce(state, state => {
            let temp = state.data[idx]
            state.data[idx] = state.data[idx - 1]
            state.data[idx - 1] = temp

        })
        setState(newState)
        save(newState)
    }

    let moveDown = (idx) => {
        if (idx > state.data.length - 2) return
        let newState = produce(state, state => {
            let temp = state.data[idx]
            state.data[idx] = state.data[idx + 1]
            state.data[idx + 1] = temp

        })
        setState(newState)
        save(newState)
    }
    if (!userId) {
        return <FrontPage />

    }
    let buttonStyle = {
        backgroundColor: "white",
        color: "black",
        border: "1px solid",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "10px",
        cursor: 'pointer'
    }

    let iconButtonStyle = {
        outline: "none",
        marginRight: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#fafafa",
        border: "#aaa",
        borderRadius: "100%",
        height: "38px",
        width: "38px",
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        cursor: "pointer"
    }

    let plusButtonStyle = state.data.length === 0 ? {
        height: "50px",
        width: "50px"
    } : { height: "25px", width: "25px" }

    let between = idx => {
        return (
            <IconContext.Provider value={{ size: 10 }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "5px", marginTop: "5px" }}>
                    <button disabled={state.recording} style={{ ...iconButtonStyle, ...plusButtonStyle }} onClick={() => {
                        setState(produce(state, state => {
                            state.adding = { idx: idx + 1 }
                        }))
                    }}><MdAdd /></button>
                    {
                        (<button disabled={state.recording} style={{ ...iconButtonStyle, height: "25px", width: "25px", visibility: (idx >= state.data.length - 1 || idx < 0) && "hidden" }} onClick={() => moveDown(idx)}><MdSwapVert /></button>)
                    }
                    {idx >= state.data.length - 1 || idx < 0 || <hr style={{ opacity: 0.8 }} />}
                </div>

            </IconContext.Provider>)
    }
    let elements = [between(-1)]

    for (let idx = 0; idx < state.data.length; idx++) {
        let slide = state.data[idx]
        let e = (<div style={{ border: "1px black", padding: "5px", display: "flex" }}>
            <img
                onClick={() => {
                    setState(produce(state, state => {
                        state.changeImageModal = { idx }
                    }))
                }}
                style={{ objectFit: "contain", border: "1px solid #ddd" }}
                height={"300px"}
                width={"400px"}
                src={urlFromId(slide.imageId)} />
            <textarea
                style={{ marginLeft: "10px", marginRight: "10px", fontSize: "13pt", width: "400px" }}
                value={slide.text || ""}
                onChange={evt => textAreaChanged(idx, evt)} />
            <div >





                <div style={{ marginLeft: "20px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>

                    <div style={{ display: "flex" }}>{state.recording ? state.recording.idx === idx && <button style={iconButtonStyle} onClick={() => stopRecording()}><MdStop /></button>
                        : <button
                            style={iconButtonStyle}
                            onClick={() => recordForSlide(idx)}>
                            <MdRecordVoiceOver size={22} />
                        </button>}

                        {(slide.soundURL || slide.soundId) && (state.playingAudio ?
                            (<button
                                disabled={state.recording}
                                style={iconButtonStyle}
                                onClick={stopAudio}><MdStop size={22} /></button>)
                            : (<button
                                disabled={state.recording}
                                style={iconButtonStyle}
                                onClick={() => {
                                    playSound(slide.soundURL || urlFromId(slide.soundId))
                                }}><MdPlayArrow size={22} /></button>))
                        }

                        <div style={{ marginLeft: "50px" }}>
                            <div style={{ display: "flex" }}>
                                <input
                                    accept="audio/*"
                                    onChange={ev => {
                                        let file = ev.target.files && ev.target.files[0]
                                        if (file) uploadSoundFile(file, idx)
                                    }}
                                    type="file"
                                    id={"upload-audio-file" + idx}
                                    class="inputfile" />
                                <label
                                    disabled={state.recording}
                                    style={{ ...iconButtonStyle }}

                                    for={"upload-audio-file" + idx}>
                                    <BsUpload size={22} /></label>

                                {(slide.soundURL || slide.soundId) &&
                                    (<button
                                        disabled={state.recording}
                                        style={{ ...iconButtonStyle }}
                                        onClick={() => {
                                            let newState = produce(state, state => {
                                                state.data[idx].soundURL = undefined
                                                state.data[idx].soundId = undefined

                                            })
                                            setState(newState)
                                            save(newState)
                                        }}><GrVolumeMute size={22} /></button>)

                                }

                            </div>
                            <div>
                                {
                                    slide.comments && slide.comments.length > 0 && (<div>
                                        {`Comments & Questions`}
                                        <div style={{ border: "1px solid", padding: "20px", overflow: "scroll", height: "300px" }}>
                                            <ul>
                                                {slide.comments.map(comment => {
                                                    return (<li onClick={() => {
                                                        setState(produce(state, state => {
                                                            state.commentDetails = { id: comment.id, contents: comment.contents }
                                                        }))
                                                    }} style={{ marginBottom: "10px" }}>
                                                        {comment.contents}
                                                    </li>)
                                                })}
                                            </ul>

                                        </div></div>)
                                }
                            </div>
                        </div>

                    </div>


                    <button disabled={state.recording} style={iconButtonStyle} onClick={() => deleteSlide(idx)}><FaTrash /></button>
                </div>



            </div>

        </div >
        )

        elements.push(e)
        elements.push(between(idx))

    }

    let micSelectFunc = () => {
        function gotDevices(deviceInfos) {
            let devices = []
            for (let i = 0; i !== deviceInfos.length; ++i) {
                var deviceInfo = deviceInfos[i];
                if (deviceInfo.kind === 'audioinput') {
                    devices.push({ label: deviceInfo.label || 'Microphone ' + i, deviceId: deviceInfo.deviceId })
                }
                // if (deviceInfo.kind === 'audioinput') {
                //     console.log("device", deviceInfo.label || 'Microphone ' + i,
                //         deviceInfo.deviceId);
                // }
            }
            setState(state => produce(state, state => {
                state.micChoices = devices
            }))
            console.log("devices set", devices)
        }
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            navigator.mediaDevices.enumerateDevices()
                .then(gotDevices)
                .then(() => {
                    stream.getTracks() // get all tracks from the MediaStream
                        .forEach(track => track.stop()); // stop each of them
                })
        })
        //.catch(errorCallback)
    }

    // useEffect(() => {
    //     if (!state.micSelect) return
    //     function gotDevices(deviceInfos) {
    //         for (var i = 0; i !== deviceInfos.length; ++i) {
    //             var deviceInfo = deviceInfos[i];
    //             if (deviceInfo.kind === 'audioinput') {
    //                 console.log(deviceInfo.label || 'Microphone ' + i,
    //                     deviceInfo.deviceId);
    //             }
    //         }
    //     }
    //     navigator.mediaDevices.enumerateDevices()
    //         .then(gotDevices)
    //         .catch(errorCallback)
    // }, [state.micSelect])
    let setMicIndex = i => {
        let deviceId = state.micChoices[i].deviceId
        window.localStorage.setItem('lastMicDeviceId', deviceId);
        window.localStorage.setItem('lastMicDeviceLabel', state.micChoices[i].label);
        setState(state => produce(state, state => {
            state.micIndexSelected = i
            state.deviceIdSelected = state.micChoices[i].deviceId
        }))
    }
    if (state.micSelect) {
        // let setMicIndex = i => {
        //     setState(produce(state, state => {
        //         state.micIndexSelected = i
        //     }))
        // }
        return (<div
            style={{ position: "fixed", height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "500px" }}>
                <div style={{ textalign: "center" }}>Select a microphone</div>
                {(state.micChoices || []).map((choice, i) => {
                    if (!choice.deviceId) return undefined
                    return <div><input type="radio" onClick={() => setMicIndex(i)} checked={state.micIndexSelected === i} /> {choice.label}</div>


                }).filter(x => x)}
                <div style={{ marginTop: "30px", display: "flex", justifyContent: "center" }}><button onClick={() => {
                    setState(produce(state, state => {
                        state.micSelect = false
                    }))
                }}>close</button></div>
            </div>


        </div>)
    }


    return (
        <div style={{ margin: "30px" }}>


            {/* <div
                style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "500px" }}>
                    <input type="radio" onClick={() => setMicIndex(0)} checked={state.micIndexSelected === 0} />
                    <input type="radio" onClick={() => setMicIndex(1)} checked={state.micIndexSelected === 1} />
                </div>


            </div> */}


            {state.commentDetails && (
                <div style={{ position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255,0.5)" }}>
                    <div style={{ border: "1px solid", display: "flex", justifyContent: "center", alignItems: "center", padding: "50px", backgroundColor: "white", flexDirection: "column", position: "relative" }}>
                        {state.commentDetails.contents}
                        <div>
                            <button onClick={async () => {
                                post('/delete-comment', {
                                    commentId: state.commentDetails.id
                                })
                                setState(produce(state, state => {
                                    state.data.forEach(d => {
                                        d.comments = d.comments.filter(x => x.id !== state.commentDetails.id)
                                    })
                                    state.commentDetails = undefined
                                }))
                            }}>Delete</button>
                            <button onClick={() => {
                                setState(produce(state, state => {
                                    state.commentDetails = undefined
                                }))

                            }}> Close this window</button>
                        </div>
                    </div>
                </div>)}



            {state.changeImageModal && (
                <div style={{ position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255,0.5)" }}>
                    <div style={{ border: "1px solid", display: "flex", justifyContent: "center", alignItems: "center", padding: "50px", backgroundColor: "white", flexDirection: "column", position: "relative" }}>
                        <div>Change the image <input onChange={evt => changeImageAtIndex(evt, state.changeImageModal.idx)} type="file" /></div>
                        <div style={{ position: "absolute", right: "0px", top: "0px" }}><button
                            style={{ backgroundColor: "rgba(0,0,0,0)", border: "none" }}
                            onClick={() => {
                                setState(produce(state, state => {
                                    state.changeImageModal = undefined
                                }))
                            }}>X</button></div>
                    </div>
                </div>)}
            {state.uploading && (
                <div style={{ position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255,0.5)" }}>
                    <ClipLoader size={100} />
                </div>)}



            <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{state.slideshowName}</div>

                    <div
                        style={{ marginLeft: "2px", cursor: "pointer" }}
                        onClick={async () => {
                            let newName = window.prompt("pick a new name")
                            if (newName === null) return
                            if (newName < 2) {
                                alert("Pick a longer name")
                                return
                            }
                            let result = await post('/change-slideshow-name', {
                                id: slideshowId,
                                name: newName
                            })
                            let parsed = JSON.parse(result)
                            if (!parsed.success) {
                                alert(parsed.reason)
                                return
                            }
                            alert("slideshow name changed")
                            setState(produce(state, state => {
                                state.slideshowName = newName
                            }))

                        }}><MdModeEdit /></div>
                    <button style={{ marginLeft: "30px" }} onClick={() => {
                        micSelectFunc()

                        setState(state => produce(state, state => {

                            state.micSelect = true
                        }))
                    }}>Select mic</button>
                </div>


                <button onClick={() => publish(state)}>Publish</button> Shared link
            <a target="_blank" style={{ marginLeft: "10px" }} href={`${frontendhost}/web/slideshow/view/${slideshowId}`}>{`${frontendhost}/web/slideshow/view/${slideshowId}`}</a>
            </div>

            {state.data.length > 0 && <div style={{ marginTop: "20px", marginBottom: "20px" }}>Replace all images <input onChange={replaceAllImages} type="file" accept=".pdf" /></div>}

            {
                elements
            }

            {/* {
                state.data.length === 0 && <div style={{ fontSize: "16pt" }}>
                    <button
                        style={buttonStyle}
                        onClick={() => {
                            setState(produce(state, state => {
                                state.adding = { idx: 0 }
                            }))
                        }}>Add an image or pdf</button>

                </div>
            } */}

            {
                state.adding && (
                    <div style={{ position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255,0.5)" }}>

                        <div style={{ zIndex: 10, border: "1px solid", display: "flex", justifyContent: "center", alignItems: "center", padding: "50px", backgroundColor: "white", flexDirection: "column", position: "relative", backgroundColor: "#fafafa" }}>
                            <div style={{ position: "absolute", top: "3px", right: "8px", cursor: "pointer", fontSize: "20px" }} onClick={() => {
                                setState(produce(state, state => {
                                    state.adding = false
                                }))
                            }}>x</div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>Add an image</div>
                                        </td>
                                        <td>
                                            <div><input onChange={evt => imageChange(evt, state.adding.idx)} type="file" accept=".jpg,.jpeg,.png,.gif" /></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>Upload a pdf</div>
                                        </td>
                                        <td>
                                            <div> <input onChange={evt => pdfChange(evt, state.adding.idx)} type="file" accept=".pdf" /></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
            }
        </div >

    )

}
export default EditSlideshow