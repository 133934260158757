import React from 'react'
import { useEffect, useState } from 'react'
import produce from 'immer'
import { post, urlFromId } from './requests'
import { useSelector } from 'react-redux'
import { dispatch, getState } from './store'
import { playSound, stopSound } from './play-combined'
import { detect } from 'detect-browser';
import { FaQuestion, FaPlay, FaBackward, FaForward, FaSearch, FaStop, FaPause } from 'react-icons/fa'
import { GiTurtle } from 'react-icons/gi'
import { BsPencilSquare } from "react-icons/bs";
import { BsLayoutTextSidebarReverse, BsCardImage } from 'react-icons/bs'
import { MdBorderAll } from 'react-icons/md'
import { AiFillPrinter } from 'react-icons/ai'
const delay = t => new Promise((res, rej) => setTimeout(res, t))

let centered = x => {
    return (<div style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div>{x}</div>
    </div>)
}

let presentWait = (presentingId, time, playbackRate) => {
    setTimeout(() => {
        if (getState().presentingId === presentingId) {
            if (getState().presentingSlideIndex < getState().viewedSlideshow.length - 1) {
                dispatch({ type: "increment-viewed-slide-index" })
                presentSound(presentingId, playbackRate)
            } else {
                alert("Slideshow has ended")
            }
        }
    }, time)
}

let presentSound = (presentingId, playbackRate) => {
    const slides = getState().viewedSlideshow
    const slideIndex = getState().presentingSlideIndex
    const timeAtPause = getState().timeAtPause
    let slide = slides[slideIndex]
    if (slide === undefined) throw new Error("what")
    if (slide.soundId && getState().viewedSoundfile) {
        let soundFile = getState().viewedSoundfile
        let url = urlFromId(soundFile)
        playSound(
            url,
            timeAtPause || slide.soundStart,
            slide.soundEnd,
            () => {

                if (getState().presentingId === presentingId) {
                    presentWait(presentingId, 2000, playbackRate)
                }
            },
            playbackRate,
            t => {
                dispatch({ type: "time-at-pause", payload: t })
            })

        return
    }

    presentWait(presentingId, 5000)
}

let pid = 0
let getNextPresentationId = () => {
    pid++
    return pid
}

let present = (playbackRate) => {
    let presentingId = getNextPresentationId()
    dispatch({ type: "set-presenting-id", presentingId })
    dispatch({ type: "set-presenting-status", presenting: true })
    presentSound(presentingId, playbackRate)
}

let stopPresenting = (resetTime) => {
    if (resetTime) {
        dispatch({ type: "time-at-pause", payload: undefined })
    }
    stopSound()
    let presentingId = getNextPresentationId()
    dispatch({ type: "set-presenting-id", presentingId })
    dispatch({ type: "set-presenting-status", presenting: false })
}


let nextSlide = () => {
    stopPresenting(true)
    if (getState().presentingSlideIndex < getState().viewedSlideshow.length - 1) {
        dispatch({ type: "increment-viewed-slide-index" })
    }
}

let previousSlide = () => {
    stopPresenting(true)
    if (getState().presentingSlideIndex > 0) {
        dispatch({ type: "decrement-viewed-slide-index" })
    }
}


let getStringContext = (str, substr) => {
    let index = Math.max(0, str.indexOf(substr) - 10)
    return str.slice(index, index + 25)
}

let nextAudioSpeed = str => {
    if (str === "1.0") return "1.2"
    if (str === "0.78") return "1.0"
    if (str === "1.2") return "0.78"
}

function ViewSlideshow({ match }) {
    let slideshowId = match.params.slideshowId
    // const presentingSlideId = useSelector(x => x.presentingSlideId)
    const slides = useSelector(x => x.viewedSlideshow)
    const slideIndex = useSelector(x => x.presentingSlideIndex)
    const presentingStatus = useSelector(x => x.presentingStatus)
    const initialPress = useSelector(x => x.initialPress)
    const [state, setState] = useState({
        audioSpeed: "1.0",
        nopressed: true,
        searchTextInput: "",
        searchText: false,
        clicked: true,
        invalidId: false,
        firstClick: false,
        comment: undefined
    })
    let addComment = () => {
        stopPresenting(false)
        setState(produce(state, state => {
            state.comment = ""
        }))
    }
    let searchText = () => {
        stopPresenting(false)
        setState(state => produce(state, state => {
            state.searchText = true
        }))
    }

    useEffect(() => {
        let t = setTimeout(() => {
            if (!state.clicked) return
            setState(produce(state, state => {
                if (state.firstClick) state.clicked = false
            }))
        }, 1500)
        return () => clearTimeout(t)
    })

    // useEffect(() => {
    //     const browser = detect();
    //     if (
    //         browser && browser.name && browser.name.toLowerCase() === "safari" && browser.os && browser.os.toLowerCase() === "ios"
    //     ) {

    //         alert("The safari browser on mobile devices will not play sound properly. Try using another browser.")
    //     }

    // }, [])
    let playPressed = () => {
        setState(produce(state, state => {
            state.firstClick = true
        }))
        let sp = parseFloat(state.audioSpeed)

        present(sp)
    }

    useEffect(() => {
        let cb = ev => {
            switch (ev.key) {
                case "ArrowLeft":
                    previousSlide()
                    // Left pressed
                    break;
                case "ArrowRight":
                    nextSlide()
                    // Right pressed
                    break;
                case "ArrowUp":
                    // Up pressed
                    break;
                case "ArrowDown":
                    // Down pressed
                    break;
                case " ":
                    if (presentingStatus) stopPresenting(false)
                    else playPressed()
                    // Space
                    break;
            }


        }

        document.addEventListener('keydown', cb)
        return () => { document.removeEventListener('keydown', cb) }
    })

    useEffect(() => {
        const act = async () => {
            let result = await post('/get-published', {
                slideshowId: slideshowId
            })
            result = JSON.parse(result)

            const slideshow = result.slideshow
            dispatch({ type: "set-presenting-index", index: 0 })
            if (slideshow) {
                dispatch({ type: "set-viewed-slideshow", slideshow })
            } else {
                setState(produce(state, state => {
                    state.invalidId = true
                }))

            }
        }
        act()
    }, [slideshowId])
    if (state.invalidId) return centered("Slideshow not found. Maybe you forgot to click the publish button?")
    if (!slides) return "loading slides"
    if (slideIndex === undefined) return "loading slide index"
    if (slides.length === 0) return centered("This project has no slides")

    let slide = slides[slideIndex]
    if (slide === undefined) {
        return "error in slide"
    }
    let buttonStyle = { outline: "none", backgroundColor: "rgba(0,0,0,0)", border: "none", margin: "10px", cursor: "pointer" }


    let changeAudioSpeed = () => {
        stopPresenting(false)
        setState(produce(state, state => {
            state.audioSpeed = nextAudioSpeed(state.audioSpeed)
        }))
    }

    return (<div

        onMouseMove={() => {
            setState(produce(state, state => {

                state.clicked = true
            }))
        }}
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}>

        {
            state.searchText && (
                <div style={{ zIndex: 10, position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255,0.5)" }}>
                    <div style={{ border: "1px solid", display: "flex", justifyContent: "center", alignItems: "center", padding: "50px", backgroundColor: "white", flexDirection: "column", position: "relative", maxHeight: "50%", maxWidth: "50%" }}>
                        <button style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            border: "none",
                            backgroundColor: "rgba(0,0,0,0)"
                        }}
                            onClick={
                                () => {
                                    setState(produce(state, state => {
                                        state.searchText = false
                                    }))
                                }
                            }>x</button>
                        <div><input value={state.searchTextInput} onChange={evt => {
                            setState(produce(state, state => {
                                state.searchTextInput = evt.target.value
                            }))
                        }} value={state.searchTextInput} />
                            <span style={{ marginLeft: "5px", position: "relative", top: "3px" }}><FaSearch size={15} /></span>
                        </div>
                        <div style={{ display: "flex" }} >
                            <div style={{ width: "200px", marginRight: "20px" }}>
                                {slides
                                    .map((x, idx) => ({ ...x, indexInArray: idx }))
                                    .filter(x => state.searchTextInput.length > 1 && x.text && x.text.includes(state.searchTextInput)).map((slide, idx) => {
                                        let context = getStringContext(slide.text, state.searchTextInput)
                                        return <div
                                            style={{ cursor: "pointer", marginBottom: "10px" }}
                                            onClick={() => {
                                                setState(produce(state, state => {
                                                    state.searchImageId = slide.imageId
                                                    state.searchImageIndex = slide.indexInArray
                                                }))
                                            }}>{context}</div>
                                    })}
                            </div>

                            <div style={{
                                width: "300px",
                                height: "300px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                {state.searchImageId !== undefined &&
                                    <img
                                        style={{ maxHeight: "300px", maxWidth: "300px", cursor: "pointer" }}
                                        onClick={() => {
                                            setState(produce(state, state => {
                                                state.searchText = false
                                            }))
                                            dispatch({ type: "set-presenting-index", index: state.searchImageIndex })
                                        }}
                                        src={urlFromId(state.searchImageId)} />
                                }
                            </div>

                        </div>


                    </div>
                </div>
            )
        }

        <div style={{ flex: 1, minHeight: 0, position: "relative", display: "flex", flexDirection: "center", alignItems: "center", justifyContent: "center" }}>

            <img
                style={{ flex: 1, minWidth: "33%", maxHeight: `100%`, objectFit: `contain`, maxWidth: state.showText ? "50%" : "100%" }}
                src={urlFromId(slide.imageId)} />
            {state.comment !== undefined && <div style={{ flex: 1, width: "33vw", justifyContent: "center" }}>
                <div style={{ marginBottom: "20px" }}>Question or suggestion for this slide?</div>
                <textarea style={{ minHeight: "200px", minWidth: "200px" }} value={state.comment} onChange={ev => {
                    setState(produce(state, state => {
                        state.comment = ev.target.value
                    }))
                }} />
                <div >
                    <button onClick={() => {
                        setState(produce(state, state => {
                            state.comment = undefined
                        }))
                    }}>Cancel</button>
                    <button style={{ marginLeft: "10px" }} onClick={async () => {
                        let result = await post('/add-comment', {
                            presentationId: slideshowId,
                            resourceId: slides[slideIndex].slideId,
                            contents: state.comment
                        })
                        setState(produce(state, state => {
                            state.comment = undefined
                        }))
                        alert("Comment sent")
                    }}>Send to author</button>

                </div>
            </div>}
            {state.showText && slide.text && <pre style={{ flex: 1, padding: "50px", whiteSpace: "pre-line" }}>{slide.text}</pre>}
        </div>

        {<div

            onClick={() =>
                setState(state => produce(state, state => {

                    state.clicked = true
                }))
            }
            style={{
                position: "absolute",
                opacity: state.clicked ? 1 : 0,
                left: "0px",
                right: "0px",
                top: "0px",
                bottom: "0px",
                transition: "opacity 0.3s",
                pointerEvents: "none",
                background: "linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 76%, rgba(187,187,187,0.5) 100%)",
                // backgroundColor: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 11%, rgba(255,255,255,1) 100%);",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center"

            }}>
            <div style={{

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "20px",
                right: "20px",
                borderRadius: "100%",
                fontSize: "20px",
                height: "80px",
                width: "80px",
                backgroundColor: "rgba(255,255,255,0.5)"
            }}><div>{slideIndex + 1} / {slides.length}</div></div>
            <div style={{ position: "absolute", right: "0px", left: "0px", bottom: "30px", left: "10px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <div>{initialPress || (getState().viewedSoundfile && <div style={{ fontSize: "40pt" }}>This presentation has audio</div>)}</div>
                <div> {presentingStatus && !slide.soundId && <span style={{ marginLeft: "30px" }}>This slide has no sound</span>}</div>
                <div style={{ pointerEvents: "all" }}>
                    <button
                        style={buttonStyle}
                        disabled={slideIndex === 0} onClick={previousSlide}><FaBackward size={30} /></button>
                    {presentingStatus ?
                        <button style={buttonStyle} onClick={() => stopPresenting(false)}>
                            <FaPause size={30} />
                        </button> :
                        <button
                            style={buttonStyle}
                            onClick={playPressed}>
                            <FaPlay size={30} />
                        </button>}
                    <button
                        style={buttonStyle}
                        disabled={slideIndex >= slides.length - 1} onClick={() => { stopPresenting(true) }}>
                        <FaStop size={30} />

                    </button>
                    <button
                        style={buttonStyle}
                        disabled={slideIndex >= slides.length - 1} onClick={nextSlide}>
                        <FaForward size={30} />

                    </button>
                    <button
                        style={{ ...buttonStyle, fontSize: "30px" }}
                        onClick={changeAudioSpeed}>
                        {state.audioSpeed}x


                    </button>
                    <button
                        style={buttonStyle}
                        onClick={searchText}><FaSearch size={30} /></button>
                    <button
                        style={{
                            ...buttonStyle,
                            visibility: !slide.slideId && "hidden",
                            pointerEvents: !slide.slideId && "none"
                        }}
                        onClick={addComment}><BsPencilSquare size={30} /></button>
                    <button
                        style={{
                            ...buttonStyle,
                            visibility: !slide.text && "hidden",
                            pointerEvents: !slide.text && "none"
                        }}
                        onClick={() => {
                            setState(produce(state, state => {
                                state.showText = !state.showText
                            }))

                        }}>{state.showText ? <BsCardImage size={30} /> : <BsLayoutTextSidebarReverse size={30} />}</button>

                    <button
                        style={buttonStyle}
                        onClick={() => {
                            window.open('https://slideswithsounds.com/web/slideshow/view/1apFcfvEZfU91Bi1homvtCDn3k5JctC0N3PoENhhW1fo', '_blank');
                        }}><FaQuestion size={30} />
                    </button>

                    <button
                        style={buttonStyle}
                        onClick={() => {
                            window.open('https://slideswithsounds.com/web/slideshow/print/' + slideshowId, '_blank');
                        }}><AiFillPrinter size={30} />
                    </button>

                </div>
            </div>
        </div>
        }

    </div>)

}
export default ViewSlideshow