let playing = undefined
const getMediaErrorMessage = error => Object.keys(Object.getPrototypeOf(error.currentTarget.error)).find(key => error.currentTarget.error[key] === error.currentTarget.error.code);

let playSound = (url, finishedCallback) => {
    try {
        if (playing) playing.pause()
    } finally {
    }
    playing = new Audio(url)
    playing.onended = finishedCallback;
    playing.addEventListener('error', e => {
        console.log(`Media error occurred: ${getMediaErrorMessage(e)}`)
    })




    try {
        playing.play()
    } catch (e) {
        console.log("error", e.toString())
    } finally { }
    console.log("done play sound function")
}

let stopSound = () => {
    try {
        if (playing) {
            playing.pause()
        }
    } finally { }
}

export { playSound, stopSound }