let playing = undefined
let globalURL = undefined
let finishedTime = undefined
let updateEventListener = undefined


const getMediaErrorMessage = error => Object.keys(Object.getPrototypeOf(error.currentTarget.error)).find(key => error.currentTarget.error[key] === error.currentTarget.error.code);


let listenerMap = {}


let playSound = (url, startTime, endTime, finishedCallback, playbackRate, timeUpdateCB) => {
    try {
        if (playing) playing.pause()
    } finally {
    }
    if (globalURL !== url) {
        globalURL = url
        playing = new Audio(url);

        playing.onended = () => {
            if (finishedCallback) {
                finishedCallback();
                finishedCallback = undefined
            }
        }
        if (updateEventListener) {
            playing.removeEventListener("timeupdate", updateEventListener)
        }
        updateEventListener = () => {
            let currentTime = playing.currentTime
            timeUpdateCB(currentTime)
            if (currentTime > endTime) {
                playing.pause()
                if (finishedCallback) {
                    finishedCallback()
                    finishedCallback = undefined
                }
            }
        }
        playing.addEventListener("timeupdate", updateEventListener)
        // playing.addEventListener('error', e => {
        //     console.log(`Media error occurred: ${getMediaErrorMessage(e)}`)
        // })
        playing.currentTime = startTime
        if (playbackRate) playing.playbackRate = playbackRate
        else playing.playbackRate = 1
        playing.play()
        return
    } else {
        playing.pause()

        if (updateEventListener) {
            playing.removeEventListener("timeupdate", updateEventListener)
        }
        updateEventListener = evt => {
            timeUpdateCB(playing.currentTime)

            if (playing.currentTime > endTime) {
                playing.pause()
                if (finishedCallback) {

                    finishedCallback()
                    finishedCallback = undefined
                }
            }
        }
        playing.addEventListener("timeupdate", updateEventListener)

        playing.currentTime = startTime
        if (playbackRate) playing.playbackRate = playbackRate
        else playing.playbackRate = 1
        playing.play()
    }
    finishedTime = endTime


    // try {
    //     playing.play()
    // } catch (e) {
    //     console.log("error", e.toString())
    // } finally { }
    // console.log("done play sound function")
}

let stopSound = () => {
    try {
        if (playing) {
            playing.pause()
            if (updateEventListener) {
                playing.removeEventListener("timeupdate", updateEventListener)
            }
        }
    } finally { }
}

export { playSound, stopSound }