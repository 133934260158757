import React, { useState, Component } from 'react'
import firebase from './my-firebase'
import produce from 'immer'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import ClipLoader from "react-spinners/ClipLoader";

import './index.css'
let loginWithGoogle = async () => {
    await firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())
}



let emailSignup = async (email, password) => {
    try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
    } catch (err) {
        alert(err.code)
        return
    }
}

let emailSignin = async (email, password) => {
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (err) {
        alert(err.code)
        return
    }
}

let centered = x => {
    return (<div style={{ position: "fixed", zIndex: 10, top: "0px", right: "0px", bottom: "0px", left: "0px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div>{x}</div>
    </div>)
}

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailInput: ""
        };
    }
    handleSubmit = evt => {
        evt.preventDefault();
        firebase.auth().sendPasswordResetEmail(this.state.emailInput)
        alert("You should receive an email shortly")
        this.props.onClose()
        //this.props.formSubmission(this.state.emailInput);
    };
    render() {
        return (
            <div>
                <div className={"signup-blanket"} />
                <div className={"signup-modal-container"}>
                    <div className={"signup-modal-bubble"}>
                        <div className={"signup-close-button"} onClick={this.props.onClose}>
                            <FaTimes />
                        </div>
                        <div className={"signup-modal-inner-bubble"}>
                            <div className={"signup-title"}>{this.props.title}</div>
                            <form onSubmit={this.handleSubmit}>
                                <div>
                                    <input
                                        onChange={evt =>
                                            this.setState({ emailInput: evt.target.value })
                                        }
                                        type="text"
                                        placeholder="Your email"
                                        className={"signup-input"}
                                    />
                                </div>

                                <div>
                                    <input
                                        type="submit"
                                        className={"signup-submit"}
                                        value="Reset password"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameInput: "",
            passwordInput: "",
            forgotpassword: false
        };
    }
    google = async () => {

        try {
            await firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())
        } catch (err) {
            alert(err.code)
            return

        }
        this.props.onClose()
    }
    handleSubmit = evt => {
        evt.preventDefault();
        if (this.props.signup) {
            emailSignup(
                this.state.usernameInput,
                this.state.passwordInput
            );
            this.props.onClose()
            return
        }
        emailSignin(
            this.state.usernameInput,
            this.state.passwordInput
        )
        this.props.onClose()

    };
    render() {
        if (this.state.forgotpassword) {
            return <ForgotPassword onClose={this.props.onClose} />
        }
        let submitTitle = "log in"
        let title = "Log in"
        if (this.props.signup) {
            submitTitle = "Create account"
            title = "Sign up"
        }
        return (
            <div>
                <div className={"signup-blanket"} />
                <div className={"signup-modal-container"}>
                    <div className={"signup-modal-bubble"}>
                        <div className={"signup-close-button"} onClick={this.props.onClose}>
                            <FaTimes />
                        </div>
                        <div className={"signup-modal-inner-bubble"}>
                            <div className={"signup-title"}>{title}</div>
                            <form className={"signup-column"} onSubmit={this.handleSubmit}>

                                <input

                                    onChange={evt =>
                                        this.setState({ usernameInput: evt.target.value })
                                    }
                                    type="email"
                                    placeholder="Your email"
                                    className={"signup-input"}
                                />


                                <input
                                    onChange={evt =>
                                        this.setState({ passwordInput: evt.target.value })
                                    }
                                    type="password"
                                    placeholder="Password"
                                    className={"signup-input"}
                                />


                                <button className={`signup-submit squareButton`}
                                >{submitTitle}</button>

                            </form>
                            <div className={"or-container"}>
                                <hr />
                                <div className={"or-class"}>OR</div>
                                <hr />
                            </div>
                            <div className={"google-container"}>
                                <button
                                    style={{ cursor: "pointer" }}
                                    onClick={this.google}
                                    type="button"
                                    className={"google-button"}>

                                    <span className={"google-button__icon"}>
                                        <svg
                                            viewBox="0 0 366 372"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                                                id="Shape"
                                                fill="#EA4335"
                                            />
                                            <path
                                                d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                                                id="Shape"
                                                fill="#FBBC05"
                                            />
                                            <path
                                                d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                                                id="Shape"
                                                fill="#4285F4"
                                            />
                                            <path
                                                d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                                                fill="#34A853"
                                            />
                                        </svg>
                                    </span>
                                    <span className={"google-button__text"}>
                                        log in with Google
                    </span>
                                </button>
                            </div>
                        </div>
                        {!this.props.signup ? (
                            <div
                                className={"forgotpassword-container"}
                                onClick={() => this.setState({ forgotpassword: true })}
                            >
                                <div className={"forgotpassword"}> Forgot password?</div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}



// function LoginModal() {
//     centered(<div style={{ padding: "50px", backgroundColor: "white", border: "1px solid" }}>
//         <div style={{ display: "flex" }}>
//             <div>
//                 <div style={{ margin: "10px" }}>Username </div>
//                 <div style={{ margin: "10px" }}>Password </div>
//             </div>
//             <div>
//                 <div style={{ margin: "10px" }}>  <input type="text" /></div>
//                 <div style={{ margin: "10px" }}><input type="text" /></div>
//             </div>
//         </div>
//         <div style={{ display: "flex", justifyContent: "center" }}>
//             <div style={{ margin: "10px" }}> <button
//                 style={{ color: "#020202", border: "1px solid #444", padding: "10px", cursor: "pointer", backgroundColor: "white", color: "black", fontSize: "20px", fontWeight: "100", marginLeft: "10px" }}>
//                 Sign in</button>
//             </div>
//         </div>

//         <div style={{ display: "flex", justifyContent: "center" }}>
//             <div style={{ margin: "30px" }}>Or sign in with <button
//                 style={{ color: "#020202", border: "1px solid #444", padding: "10px", cursor: "pointer", backgroundColor: "white", color: "black", fontSize: "20px", fontWeight: "100", marginLeft: "10px" }}>
//                 Google</button>
//             </div>
//         </div>
//     </div>)

// }

function FrontPage() {
    let authCheck = useSelector(x => x.authCheck)
    const [state, setState] = useState({
        login: false,
        create: false
    })
    let onClose = () => {
        setState(produce(state, state => {
            state.login = false
            state.create = false
        }))
    }
    if (!authCheck) {
        return (<div style={{ position: "fixed", left: "0px", right: "0px", top: "0px", bottom: "0px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(255,255,255,0.5)" }}>
            <ClipLoader size={100} />
        </div>)

    }
    return (<div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {
            state.login && <Signup onClose={onClose} signup={false} />
        }
        {
            state.create && <Signup onClose={onClose} signup={true} />
        }


        {/* {!authCheck && "foobar"} */}
        <div>
            <div style={{ textAlign: "center" }}><a style={{ color: "#020202" }} target="_blank" href="https://slideswithsounds.com/web/slideshow/view/yyvh340kctrafqs">What is this?</a></div>
            <button
                style={{ width: "250px", border: "1px solid", padding: "10px", cursor: "pointer", backgroundColor: "white", color: "black", fontSize: "20px", fontWeight: "100", margin: "30px" }} /* Green */
                onClick={() => {
                    setState(produce(state, state => {
                        state.create = true
                    }))
                }}>Create an account</button>
            <button
                style={{ width: "250px", border: "1px solid", padding: "10px", cursor: "pointer", backgroundColor: "white", color: "black", fontSize: "20px", fontWeight: "100", margin: "30px" }} /* Green */
                onClick={() => {
                    setState(produce(state, state => {
                        state.login = true
                    }))
                }}>Log in</button>
        </div>
    </div >)
}
export default FrontPage
