import * as natcomp from "natural-compare-lite"
import React, { useState } from 'react'
import firebase from './my-firebase'
import { post } from './requests'
import { dispatch } from './store'
import { useSelector } from 'react-redux'
import { history } from './history'
import produce from 'immer'
import FrontPage from './Frontpage'
import { FaTrash } from 'react-icons/fa'
import ClipLoader from "react-spinners/ClipLoader";

// let sort = (arr, f) => {
//     let ret = [...arr]
//     ret.sort((a, b) => {
//         let ap = f(a)
//         let bp = f(b)
//         if (ap < bp) return -1
//         if (ap > bp) return 1
//         return 0
//     })
//     return ret
// }

const newSlideshow = async (userId) => {
    let slideshowName = window.prompt("Choose a name for your slideshow")
    if (!slideshowName || slideshowName.length < 1) return
    let response = await post("/new-slideshow", {
        userId,
        slideshowName
    })
    let parsed = JSON.parse(response)
    dispatch({ type: "add-slideshow", slideshowName, slideshowId: parsed.id })
    history.push(`/web/slideshow/modify/${parsed.id}`)
}

let cleanStr = str => {
    str = str.trim()
    str = str.replace(/\s\s+/g, ' ')
    str = str.toLowerCase()
    return str


}

function CreatorSlideshows() {
    let slideshows = useSelector(x => x.creatorSlideshows)
    let userId = useSelector(x => x.userId)
    let loginWaiting = useSelector(x => x.loginWaiting)
    const [state, setState] = useState({})

    const showDeleteModal = slideshow => {
        setState(produce(state, state => {
            state.toDelete = slideshow
        }))
    }
    if (!userId) return <FrontPage />

    if (!slideshows) return "loading slideshows..."
    slideshows = [...slideshows]
    slideshows = slideshows.sort((a, b) => natcomp(cleanStr(a.slideshow_name), cleanStr(b.slideshow_name)))
    const deleteSlideshow = () => {
        post("/delete-slideshow", {
            userId,
            slideshowId: state.toDelete.slideshow_id
        })
        dispatch({ type: "delete-creator-slideshow", slideshowId: state.toDelete.slideshow_id })
        setState(produce(state, state => {
            state.toDelete = undefined
        }))
    }
    let buttonStyle = {
        backgroundColor: "white",
        color: "black",
        border: "1px solid",
        padding: "5px",
        marginBottom: "10px",
        marginRight: "10px",
        cursor: 'pointer'
    }

    const renderedDeleteModal = (state.toDelete && <div
        style={{ position: "absolute", left: "0px", right: "0px", top: "0px", bottom: "0px", backgroundColor: "rgba(255,255,255,0.7)", display: "flex", alignItems: "center", justifyContent: "center" }}
    >
        <div style={{ border: "1px solid", padding: "10px" }}>
            Are you sure you want to delete {state.toDelete.slideshow_name}?
                        <div style={{ marginTop: "20px" }}><button
                style={buttonStyle}
                onClick={() => deleteSlideshow(state.toDelete)}>yes</button>
                <button style={buttonStyle} onClick={
                    () => {
                        setState(produce(state, state => {
                            state.toDelete = undefined
                        }))
                    }
                }>no</button></div>
        </div>
    </div >)


    return (
        <div style={{ margin: "30px" }}>
            {loginWaiting && "foobar"}
            {
                renderedDeleteModal
            }
            <div><button style={buttonStyle} onClick={() => {
                firebase.auth().signOut()
            }}>logout</button></div>
            <div style={{ marginBottom: "10px" }}><button style={buttonStyle} onClick={() => newSlideshow(userId)}>new project </button></div>
            {slideshows.length === 0 ? <div>You don't have any projects yet</div> : <div>Your projects</div>}
            <div style={{ display: "flex" }}>
                <div>
                    {slideshows.map(slideshow => {
                        return <div style={{ display: "flex", margin: "20px", fontSize: "14pt", cursor: "pointer" }}><div onClick={() => {
                            history.push(`/web/slideshow/modify/${slideshow.slideshow_id}`)
                        }}>{slideshow.slideshow_name}</div></div>
                    })}
                </div>
                <div>
                    {slideshows.map(slideshow => {
                        return <div
                            style={{ display: "flex", margin: "20px", fontSize: "14pt", cursor: "pointer" }}
                        ><span
                            onClick={() => { showDeleteModal(slideshow) }}
                            style={{ marginLeft: "30px" }}><FaTrash color="rgba(0,0,0,0.6)" size={15} /></span></div>
                    })}
                </div>
            </div>

        </div >
    )
}
export default CreatorSlideshows