import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import firebase from './my-firebase'
import { rawpost, post } from './requests'
import { store } from './store'
import { history } from './history'
import { Router } from 'react-router'

firebase.auth().onAuthStateChanged(async (user) => {
  store.dispatch({
    type: "auth-check",
  })


  if (user) {
    const urlParams = new URLSearchParams(window.location.search);
    const importId = urlParams.get('importid');

    let userId = user.uid
    if (importId) {
      let importResponse = await rawpost("/import", {
        importId: importId.trim(),
        userId
      })
      let parsed = JSON.parse(importResponse)
      if (parsed.inProgress) {
        alert("import in progress " + Math.ceil(parsed.progress) + "%")
        return
      }
      if (parsed.success) {
        alert("Slideshow imported")
      } else {
        alert("Could not find import id " + importId.trim())
      }
    }
    let response = await post("/list-all-slideshows", { userId })
    let parsed = JSON.parse(response)

    store.dispatch({
      type: "user-login",
      userId: userId,
      slideShows: parsed.data
    })
  } else {
    store.dispatch({
      type: "user-login",
      userId: undefined,
      slideShows: undefined
    })
    // No user is signed in.
  }
});

ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
