import * as firebase from 'firebase'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCG5At2ugGFM9aWpEBg1Mm75iXA2qHUSjw",
    authDomain: "easy-slideshow.firebaseapp.com",
    databaseURL: "https://easy-slideshow.firebaseio.com",
    projectId: "easy-slideshow",
    storageBucket: "easy-slideshow.appspot.com",
    messagingSenderId: "981445911638",
    appId: "1:981445911638:web:cef2cb6dcb758f229747f7",
    measurementId: "G-VMP86L2689"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase