import React, { useEffect, useState } from 'react'
import { post, urlFromId } from './requests'
import produce from 'immer'
function PrintingView({ match }) {
    let slideshowId = match.params.slideshowId
    const [state, setState] = useState({
        data: undefined
    })
    useEffect(() => {
        let run = async () => {
            let result = await post('/get-published', {
                slideshowId: slideshowId
            })
            result = JSON.parse(result)

            const slideshow = result.slideshow.data
            setState(produce(state, state => {
                state.data = slideshow
            }))
        }
        run()
    }, [])
    if (!state.data) return "loading..."
    return state.data.map(elem => {
        let imgSrc = urlFromId(elem.imageId)
        if (!elem.text) {
            return (<img
                style={{ border: "1px solid black", height: "100vh", boxSizing: 'border-box', width: "100vw", objectFit: `contain` }}
                src={imgSrc} />)

        }
        return (<div style={{ display: "flex", height: "100vh", boxSizing: 'border-box', }}><img
            style={{ height: "100vh", width: "60vw", objectFit: `contain` }}
            src={imgSrc} />
            <div style={{ boxSizing: 'border-box', borderLeft: "1px solid black", height: "100vh", fontSize: "20px", width: "40vw", display: "flex", alignItems: "center", padding: "20px" }}>{elem.text}</div>
        </div>)
    })
}
export default PrintingView